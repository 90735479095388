<template>
  <basicTable
      :tableData="tableData"
      :attrList="attrList"
      :page="page.current"
      @currentChange="handleCurrentChange"
      @sizeChange="handleSizeChange"
      :limit="page.limit"
      :total="page.total">
  </basicTable>
</template>

<script>
import { listData } from "../../api/motorroom/history";
import {onMounted, reactive, toRefs} from "vue";
export default {
  setup(){
    let state = reactive({
      page: {
        current: 1,
        limit: 10,
        total: 0
      },
      tableData:[],
      attrList: [
        {
          label: "AI值",
          prop: "ai",
          render: (row) => <div>{row.ai}</div>
        },
        {
          label: "DI值",
          prop: "di",
          render: (row) => <div>{row.di}</div>
        },
        {
          label: "温度(℃)",
          prop: "temperature",
          render: (row) => <div>{row.temperature}</div>
        },
        {
          label: "温度状态",
          prop: "temperatureStatus",
          render: (row) => <div>{row.temperatureStatus ? '异常' : '正常'}</div>
        },
        {
          label: "湿度(%rh)",
          prop: "humidity",
          render: (row) => <div>{row.humidity}</div>
        },
        {
          label: "湿度状态",
          prop: "humidityStatus",
          render: (row) => <div>{row.humidityStatus ? '异常' : '正常'}</div>
        },
        {
          label: "烟雾浓度(PPM)",
          prop: "smoke",
          render: (row) => <div>{row.smoke}</div>
        },
        {
          label: "烟雾浓度状态",
          prop: "smokeStatus",
          render: (row) => <div>{row.smokeStatus ? '异常' : '正常'}</div>
        },
        {
          label: "水侵状态",
          prop: "water",
          render: (row) => <div>{row.water ? '异常' : '正常'}</div>
        },
        {
          label: "明火状态",
          prop: "fire",
          render: (row) => <div>{row.fire ? '异常' : '正常'}</div>
        },
        {
          label: "检测时间",
          prop: "createTime",
          render: (row) => <div>{row.createTime}</div>
        },
      ]
    })

    function onLoad(){
      listData({ page:state.page.current,limit:state.page.limit }).then(res=>{
        state.tableData = res.data.data.list;
        state.page.total = res.data.data.count;
      })
    }

    function handleCurrentChange(currentPage) {
      state.page.current = currentPage;
      onLoad();
    }

    function handleSizeChange(pageSize) {
      state.page.limit = pageSize;
      onLoad();
    }

    onMounted(()=>{
      onLoad()
    })
    return{
      handleCurrentChange,
      handleSizeChange,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>